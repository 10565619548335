import type { ContentItemModel, CreatorModel } from '@domain/models';
import type { PuzzleDBO } from '@infrastructure/firebase/firestore/dto/PuzzleDBO';
import type { AuthorDBO } from '@infrastructure/firebase/firestore/dto/AuthorDBO';

export abstract class FirestorePuzzleRepository {
	protected puzzleDBOToContentItem(id: string, puzzleDBO: PuzzleDBO): ContentItemModel {
		return {
			id: id,
			type: puzzleDBO.type,
			authorId: puzzleDBO.Publisher,
			name: puzzleDBO.name,
			imageSrc: puzzleDBO.videoIconUrl || puzzleDBO.iconURL,
			keywords: puzzleDBO.keywords ? puzzleDBO.keywords.split(' ') : [],
			createdAt: puzzleDBO.createdAt.toDate(),
			publishedAt: puzzleDBO.publishedAt ? puzzleDBO.publishedAt.toDate() : null,
			imageSrcLowQuality:
				puzzleDBO.lowQualityImageUrl ||
				puzzleDBO.videoPreviewPlaceholderUrl ||
				puzzleDBO.videoPreviewUrl ||
				puzzleDBO.url,
			videoPreview: puzzleDBO.videoPreviewUrl || puzzleDBO.url,
			likes: puzzleDBO.likes,
			plays: puzzleDBO.plays,
			isAI: puzzleDBO.aiGenerated || false,
			collectionsIds: puzzleDBO.collections || [],
		};
	}

	protected creatorDBOToCreatorModel(id: string, creatorDBO: AuthorDBO): CreatorModel {
		return {
			id: id,
			name: creatorDBO?.username || '',
			avatarSrc: creatorDBO?.profileImageUrl,
			profileLink: creatorDBO?.profileLink,
			coverImageUrl: creatorDBO?.coverImageUrl,
			puzzlesCount: creatorDBO?.Puzzles,
			followersCount: creatorDBO?.Followers,
			followingCount: creatorDBO?.Following,
			status: creatorDBO?.status,
			country: creatorDBO?.Country,
			createdAt: creatorDBO?.createdAt ? creatorDBO?.createdAt.toDate() : null,
		};
	}
}
